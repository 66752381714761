.rc-shields {
  margin-right: 8px;
  margin-top: 8px;
  display: inline-flex;
  border-radius: 0px 4px 4px 0px;
}

.rc-shields-badge {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  padding: 0 7px;
  border-radius: 0px;
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
  color: #fff;
  box-shadow: 2px 2px rgba(66, 17, 17, 0.2);
}

.rc-shields-badge:first-child {
  border-radius: 4px 0px 0px 4px;
}
.rc-shields-badge:last-child {
  border-radius: 0px 4px 4px 0px;
}
.rc-shields:hover .rc-shields-badge {
  opacity: 0.7;
}
